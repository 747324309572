<template>
  <div
    class="container p0"
    v-title
    id="historyRoute"
    :data-title="$t('i18n.historyRoute')"
  >
    <div id="outer-title">{{ $t("i18n.historyRoute") }}</div>
    <el-date-picker
      v-model="date"
      type="date"
      placeholder="选择日期"
      @change="init"
    ></el-date-picker>
    <iframe
      :style="{ height: $defined.HEIGHT - 150 + 'px' }"
      :src="src"
    ></iframe>
  </div>
</template>
<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useRoute } from "vue-router";
export default {
  name: "PersonnelHistoryRoute",
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const state = reactive({
      date: new Date(),
    });

    const init = () => {
      let start_time = proxy.$defined.format(state.date) + " 00:00:00";
      let end_time = proxy.$defined.format(state.date) + " 23:59:59";
      let start = Date.parse(new Date(start_time)) / 1000;
      let end = Date.parse(new Date(end_time)) / 1000;
      let id = route.query.uid;
      state.src = "map.html?start=" + start + "&end=" + end + "&id=" + id;
    };

    init();

    return {
      ...toRefs(state),
      init,
    };
  },
};
</script>

<style lang="scss">
#historyRoute {
  .el-date-editor {
    position: absolute;
    z-index: 2;
    top: 15px;
    left: 15px;
  }
  iframe {
    width: 100%;
    border: 0;
  }
}
</style>
